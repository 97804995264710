import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../common/customMedia'

export const Security = () => {
  return (
    <>
      <Container>
        <Wrapper>
          <Title>情報セキュリティ方針</Title>

          <Subtitle>基本理念</Subtitle>
          <Preamble>
            株式会社リモハブ（以下、当社）は、「ヒトが生涯健康かつ自立して暮らせる世界を創る」をビジョンとし、事業を行っています。
            <br />
            当社の事業の中で取り扱う、お客様の情報をはじめとする情報資産は、当社の経営基盤として極めて重要なものです。
            <br />
            漏洩、き損、滅失等のリスクから、情報資産を保護することの重要性を認識した役員や従業員を含めた、情報資産を扱う者が本方針を遵守し、情報資産の機密性、完全性、可用性といった情報セキュリティを維持するための活動を実践します。
          </Preamble>

          <Subtitle>基本方針</Subtitle>

          <Article>
            <Number>1.</Number>
            <div>
              情報資産を保護するために、情報セキュリティ方針ならびに、それにまつわる規程類を策定し、これに従って業務を行うとともに、情報セキュリティに関連する法令、規制その他の規範、及び、お客様との契約事項を遵守いたします。
            </div>
          </Article>

          <Article>
            <Number>2.</Number>
            <div>
              情報資産に対して存在する漏洩、き損、滅失等のリスクを分析、評価するための基準を明確にし、体系的なリスクアセスメント方法を確立するとともに、定期的にリスクアセスメントを実施いたします。また、その結果に基づき、必要かつ適切なセキュリティ対策を実施いたします。
            </div>
          </Article>

          <Article>
            <Number>3.</Number>
            <div>
              担当役員を中心とした情報セキュリティ体制を確立するとともに、情報セキュリティに関する権限および責任を明確にいたします。また、すべての従業者が、情報セキュリティの重要性を認識し、情報資産の適切な取り扱いを確実にするために、定期的に教育、訓練および啓発を行います。
            </div>
          </Article>

          <Article>
            <Number>4.</Number>
            <div>
              情報セキュリティポリシーの遵守状況及び情報資産の取扱いについて、定期的に点検及び監査を行い、発見された不備や改善項目については、速やかに是正処置を講じます。
            </div>
          </Article>

          <Article>
            <Number>5.</Number>
            <div>
              情報セキュリティ上のイベントやインシデントの発生に対する適切な処置を講じるとともに、万一それらが発生した場合に際して、あらかじめ、被害を最小限に留めるための対応手順を確立し、有事の際には、速やかに対応するとともに、適切な是正処置を講じます。また、特に、業務中断に関わるようなインシデントについては、その管理の枠組みを確立し、定期的に見直しを行うことにより、当社の事業継続を確実にいたします。
            </div>
          </Article>

          <Article>
            <Number>6.</Number>
            <div>
              基本理念を実現するための目標を定めた情報セキュリティマネジメントシステムを確立し、これを実行するとともに、継続的に見直し、改善を行います。
            </div>
          </Article>

          <Signature>
            2022年9月1日 制定
            <br />
            株式会社リモハブ
            <br />
            代表取締役　⾕⼝ 達典
            <br />
          </Signature>
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan('medium')`
    padding: 0 20px;
  `}

  background-color: #efefef;
`

const Wrapper = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1rem;
    padding: 80px 0;
  `}
  ${customMedia.between('medium', 'large')`
    font-size: 2rem;
    margin: 0 auto;
    padding: 160px 0 80px;
    width: 735px;
  `}
  ${customMedia.greaterThan('large')`
    font-size: 2rem;
    margin: 0 auto;
    padding: 160px 0 80px;
    width: 1000px;
  `}

  color: #60656b;
`

const Title = styled.div`
  font-weight: bold;
  text-align: center;

  ${customMedia.lessThan('medium')`
    margin-bottom: 23px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 38px;
  `}
`

const Subtitle = styled.div`
  font-weight: bold;

  ${customMedia.lessThan('medium')`
    margin-bottom: 23px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 38px;
  `}
`

const Preamble = styled.div`
  ${customMedia.lessThan('medium')`
    margin-bottom: 23px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 38px;
  `}
`

const Paragraph = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-top: 40px;
  `}
`

const Article = styled.div`
  ${customMedia.lessThan('medium')`
    margin-bottom: 16px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 24px;
  `}

  display: flex
`

const Number = styled.div`
  ${customMedia.lessThan('medium')`
    margin-right: 16px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-right: 24px;
  `}
`

const Signature = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 24px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-top: 48px;
  `}

  text-align: right;
`
