import React from 'react'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'

import { Security } from '../components/Security'

const IndexPage = () => (
  <Layout>
    <SEO title="遠隔医療をデザインする会社" keywords={[]} />
    <Security />
  </Layout>
)

export default IndexPage
